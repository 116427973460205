import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import CompanyCard from './CompanyCard'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-auto-rows: min-content;

  ${media.medium`
    grid-gap: 50px;
    padding: 96px 40px 113px 40px;
  `}
`

const query = graphql`
  query {
    grummies: file(relativePath: { regex: "/portcos/grummies.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grummiesLogo: file(relativePath: { regex: "/portcos/grummies-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    finn: file(relativePath: { regex: "/portcos/finn.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    finnLogo: file(relativePath: { regex: "/portcos/finn-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellpath: file(relativePath: { regex: "/portcos/wellpath-bottles.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellpathLogo: file(relativePath: { regex: "/portcos/wellpath-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dojomojo: file(relativePath: { regex: "/portcos/dojomojo.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dojomojoLogo: file(relativePath: { regex: "/portcos/dojomojo-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    proxima: file(relativePath: { regex: "/portcos/proxima.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    proximaLogo: file(relativePath: { regex: "/portcos/proxima-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CardGrid = () => {
  const {
    grummies,
    grummiesLogo,
    finn,
    finnLogo,
    wellpath,
    wellpathLogo,
    dojomojo,
    dojomojoLogo,
    proxima,
    proximaLogo,
  } = useStaticQuery(query)

  const companies = [
    {
      name: 'Finn',
      category: 'Pet Wellness',
      url: 'https://www.petfinn.com/',
      title: 'Trusted nutrition for your trusty companion',
      description: `Vet-approved pet wellness products to make sure your pup's health is always in tip-top shape.`,
      img: finn.childImageSharp.fluid,
      logo: finnLogo.childImageSharp.fluid,
      logoWidth: 165,
      exited: false
    },
    {
      name: 'Proxima',
      category: 'Marketing',
      url: 'https://www.proxima.ai/',
      title: '',
      description: `The premier data intelligence solution that makes Facebook, Instagram, Snapchat and TikTok more profitable and scalable.`,
      img: proxima.childImageSharp.fluid,
      logo: proximaLogo.childImageSharp.fluid,
      logoWidth: 257,
      exited: false
    },
    {
      name: 'WellPath',
      category: 'Health & Wellness',
      url: 'https://www.gowellpath.com/',
      title: 'Your path to daily wellbeing',
      description: `WellPath makes better nutrition easier to achieve with its line of expertly-formulated vitamins and supplements.`,
      img: wellpath.childImageSharp.fluid,
      logo: wellpathLogo.childImageSharp.fluid,
      logoWidth: 210,
      exited: true
    },
    {
      name: 'DojoMojo',
      category: 'SaaS, SMS Marketing, B2B',
      url: 'https://www.dojomojo.com/home',
      title: 'Growth acquisition made easy',
      description: `DojoMojo is a partnerships platform that helps brands build audiences and high-intent leads at a fraction of the cost of traditional paid channels.`,
      img: dojomojo.childImageSharp.fluid,
      logo: dojomojoLogo.childImageSharp.fluid,
      logoWidth: 289,
      exited: false
    },
    {
      name: 'Grummies',
      category: 'Health & Wellness',
      url: 'https://www.eatyourgrummies.com/',
      title: 'Ancient superfoods that taste like modern candy',
      description:
        'Grummies brings all the benefits of ancient superfoods made with real organic ingredients to the masses.',
      img: grummies.childImageSharp.fluid,
      logo: grummiesLogo.childImageSharp.fluid,
      logoWidth: 125,
      exited: false
    },
  ]

  return (
    <Container>
      {companies.map(company => (
        <CompanyCard company={company} key={company.name} />
      ))}
    </Container>
  )
}

export default CardGrid
