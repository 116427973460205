import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import LinkArrow from '../shared/LinkArrow'
import { H2, P, Gotham } from '../shared/text'
import { media } from '../utils/style-utils.js'

const CopyContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  color: ${props => props.theme.colors.azure};

  display: grid;
  grid-template-rows: auto;

  ${media.small`
    padding: 34px 21px 40px 21px;
    grid-row-gap: 16px;
  `}
  ${media.large`
    padding: 49px 26px 34px 26px;
    grid-row-gap: 0;
  `}

  & > p {
    max-width: 556px;
    font-size: 24px;
    line-height: 30px;
  }
`

const IndustryPill = styled.div`
  visibility: hidden;

  width: fit-content;
  height: 32px;
  display: grid;
  place-items: center;

  background-color: ${props => props.theme.colors.alabaster};
  border: 1px solid ${props => props.theme.colors.alabaster};
  border-radius: 50px;

  font-size: 13px;
  line-height: 1;
  color: ${props => props.theme.colors.azure};
  text-transform: uppercase;

  padding: 4px 13px 4px 13px;
  margin-bottom: 22px;

  transition: 300ms ease all;
`

const ImageWrapper = styled.div`
  position: relative;
  visibility: hidden;
  overflow: hidden;

  width: 100%;
  height: 100%;
  grid-row: 1;

  ${media.small`
    max-height: 370px;
  `}
  ${media.large`
    max-height: none;
  `}
`

const swing = keyframes`
  20% { transform: rotate(15deg); } 
  40% { transform: rotate(-10deg); } 
  60% { transform: rotate(5deg); } 
  80% { transform: rotate(-5deg); } 
  100% { transform: rotate(0deg); } 
`

const LogoWrapper = styled.div`
  visibility: hidden;
  border-radius: 50%;

  height: auto;
  place-self: start;

  ${media.small`
  width: 62px;
  display: none;
  `}
  ${media.large`
  width: ${props => props.width ? props.width : 120}px;
  display: block;
  `}
  
  transition: 150ms ease all;
`

const Container = styled.div`
  position: relative;
  visibility: hidden;
  overflow: hidden;

  width: 100%;

  display: grid;

  transition: 200ms ease-in-out all;

  ${media.small`
    max-width: 600px;

    ${CopyContainer} {
      grid-row: 2;
      border-bottom: 2px solid ${props => props.theme.colors.azure};
      border-top: 2px solid ${props => props.theme.colors.azure};
    }
    ${ImageWrapper} {
      grid-row: 1;
    }
    ${H2} {
      display: block;
    }
  `}

  ${media.medium`
    border: 2px solid ${props => props.theme.colors.azure};
    ${H2} {
      display: block;
    }
  `}

  ${media.large`
    grid-template-columns: 1fr 1fr;
    max-width: 1268px;

    ${H2} {
      display: none;
    }

    &:nth-of-type(even) {
      ${CopyContainer} {
        grid-row: 1;
        grid-column: 2 / 3;
        border-left: 2px solid ${props => props.theme.colors.azure};
        border-bottom: none;
        border-top: none;
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
      ${ImageWrapper} {
        grid-row: 1;
        grid-column: 1 / 2;
      }
    }
    &:nth-of-type(odd) {
      ${CopyContainer} {
        grid-row: 1;
        grid-column: 1 / 2;
        border-right: 2px solid ${props => props.theme.colors.azure};
        border-bottom: none;
        border-top: none;
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
      ${ImageWrapper} {
        grid-row: 1;
        grid-column: 2 / 3;
        border-bottom: none;
        border-top: none;
      }
    }
  `}
`

const WebsiteLinkContainer = styled.div`
  visibility: hidden;

  position: absolute;

  ${media.small`
    top: 40px;
    right: 25px;
  `}
  ${media.medium`
    top: 55px;
    right: 30px;
  `}
  ${media.large`
    top: 65px;
    right: 30px;
  `}
`

const Exited = styled.span`
  background-color: ${props => props.theme.colors.azure};
  font-family: Apercu, sans-serif;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  place-self: end;
  margin: 20px 0 10px;

  ${media.small`
    width: 80px;
    height: 80px;
    font-size: 100%;
  `}

  ${media.large`
    font-size: 24px;
    width: 107px;
    height: 107px;
  `}

`

const AnchorLinkAnimationTrigger = styled.div`
  // Creates effect of clicked card being in middle of screen vertically
  position: absolute;
  top: -20vh;
`

const CompanyCard = ({ company }) => {
  const classNameRoot = company.name.toLowerCase()

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({ scrollTrigger: `.${classNameRoot}__image` })

    tl.to(
      `.${classNameRoot}__container, 
       .${classNameRoot}__image, 
       .${classNameRoot}__copy, 
       .${classNameRoot}__logo, 
       .${classNameRoot}__go-to-website-link`,
      { visibility: 'visible' }
    )
    tl.from(`.${classNameRoot}__container`, {
      opacity: 0,
      duration: 0.35,
      ease: 'linear',
    })

    tl.from(`.${classNameRoot}__logo`, {
      opacity: 0,
      duration: 0.45,
      y: -20,
    })
    tl.from(
      `.${classNameRoot}__go-to-website-link`,
      { opacity: 0, duration: 0.45, y: 10, x: -10, ease: 'linear' },
      '<'
    )

    tl.from(`.${classNameRoot}__image`, { opacity: 0, duration: 1 }, '<')
    tl.from(
      `.${classNameRoot}__copy`,
      {
        opacity: 0,
        duration: 0.6,
        stagger: 0.25,
        y: 10,
        ease: 'linear',
      },
      '<'
    )
  }, [classNameRoot])

  return (
    <Container className={`${classNameRoot}__container`}>
      {/* This allows the scrollTo behavior */}
      <AnchorLinkAnimationTrigger id={classNameRoot} />

      <ImageWrapper
        className={`${classNameRoot}__image`}
        as="a"
        href={company.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img
          fluid={company.img}
          alt={company.name}
          style={{ height: '100%', width: '100%' }}
        />
      </ImageWrapper>

      <CopyContainer>
      <LogoWrapper
          className={`${classNameRoot}__logo logo`}
          as="a"
          href={company.url}
          target="_blank"
          rel="noopener noreferrer"
          width={company.logoWidth}
        >
          <Img
            fluid={company.logo}
            alt={company.name}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </LogoWrapper>
        <H2 className={`${classNameRoot}__copy title`}>{company.name}</H2>
        <IndustryPill className={`${classNameRoot}__copy`}>
          <Gotham>{company.category}</Gotham>
        </IndustryPill>
        {company.title && (<P className={`${classNameRoot}__copy`}>
          <b>{company.title}</b>
        </P>)}
        <P className={`${classNameRoot}__copy`}>{company.description}</P>

        <WebsiteLinkContainer
          className={`${classNameRoot}__go-to-website-link`}
        >
          <LinkArrow copy="go to website" destination={company.url} external />
        </WebsiteLinkContainer>

        {company.exited && (
          <Exited>
            exited
          </Exited>
        )}

      </CopyContainer>
    </Container>
  )
}

export default CompanyCard
