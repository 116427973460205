import React from 'react'
import styled from 'styled-components'

import AboveTheFold from './AboveTheFold'
import CardGrid from './CardGrid'

const Main = styled.div`
  width: 100%;
`

const Portfolio = () => {
  return (
    <Main>
      <AboveTheFold />
      <CardGrid />
    </Main>
  )
}

export default Portfolio
