import React from 'react'
import styled from 'styled-components'

import Header from '../shared/Header'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  background-color: ${props => props.theme.colors.azure};
  width: 100%;
  display: grid;
  place-items: center;

  ${media.small`
    padding: 70px 20px 55px 20px;
  `}
  ${media.medium`
    padding: 100px 40px 55px 40px;
  `}
  ${media.large`
    padding: 90px 40px;
  `}
`

const AboveTheFold = () => {
  const headerConfig = {
    header: 'Our family of brands',
    subheader:
      'Pairing our passion and playbook, we build extraordinary brands that delight people and improve our customers lives',
  }

  return (
    <Container>
      <Header config={headerConfig} />
    </Container>
  )
}

export default AboveTheFold
