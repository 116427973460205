import React from 'react'

import SEO from '../../components/shared/SEO'
import Layout from '../../components/Layout'
import Portfolio from '../../components/Portfolio'

const PortfolioPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Our Family of Brands"
        description="Pairing our passion and playbook, we build extraordinary wellness brands that delight people and improve their lives for the better."
      />
      <Layout location={location}>
        <Portfolio />
      </Layout>
    </>
  )
}

export default PortfolioPage